import React from "react"
import SEO from "@components/common/SEO"
import { defaultBgColor } from "@src/build/constants"
import { graphql } from "gatsby"
import { renderBlocks } from "@src/build/helpers"
import useGeneralContext from "@components/common/hoc/useGeneralContext"
import smoothScroll from "smoothscroll"

function Page({ data, location, pageContext }) {
  const {
    seo,
    slug,
    ACFPage: { page },
  } = data.wpPage
  const [anchorTo, setAnchorTo] = React.useState(location?.state?.anchorTo)
  const { currentBgColor, setCurrentBgColor, setIsHome } = useGeneralContext()

  function scrollToAnchorHandler() {
    if (typeof window !== undefined) {
      const target = document.querySelector(`#${anchorTo}`)

      console.log({ target })

      window.scrollTo(0, target.getBoundingClientRect().y)

      console.log("fucking scroll?")

      return setAnchorTo(null)
    }
  }

  React.useEffect(() => {
    if (currentBgColor !== defaultBgColor) {
      setCurrentBgColor(defaultBgColor)
    }

    if (slug !== "homepage") {
      setIsHome(false)
    } else {
      setIsHome(true)
    }
  }, [currentBgColor])

  React.useEffect(() => {
    console.log({ anchorTo })

    if (anchorTo) {
      setTimeout(() => scrollToAnchorHandler(), 1)
    }
  }, [anchorTo])

  return (
    <React.Fragment>
      <SEO seo={seo} />
      {renderBlocks(page)}
    </React.Fragment>
  )
}

export const data = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      slug
      ACFPage {
        page {
          ... on WpPage_Acfpage_Page_FeaturedStoryBlock {
            fieldGroupName
            featuredStory {
              ... on WpStory {
                title
                uri
                ACFStory {
                  storyCover {
                    copyColor
                    coverVideoLink {
                      vimeoLinkDesktop
                      vimeoLinkMobile
                    }
                    excerpt
                    mediaAlignment
                    mediaType
                    coverImageFile {
                      altText
                      title
                      mediaDetails {
                        sizes {
                          height
                          name
                          sourceUrl
                          width
                        }
                      }
                      mediaItemUrl
                    }
                  }
                }
                ACFStoryGeneral {
                  storyPageBackgroundColor
                  imageBlendingMode
                }
              }
            }
          }
          ... on WpPage_Acfpage_Page_HeroCarouselBlock {
            fieldGroupName
            media {
              alignment
              margin
              mediaType
              vimeoLink
              file {
                altText
                mediaDetails {
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
            }
          }
          ... on WpPage_Acfpage_Page_MediaCarouselBlock {
            blendMode
            fieldGroupName
            media {
              mediaType
              image {
                altText
                title
                mediaDetails {
                  sizes {
                    height
                    name
                    sourceUrl
                    width
                  }
                }
                mediaItemUrl
              }
              videoLink {
                fieldGroupName
                landscapeLink
                portraitLink
              }
            }
          }
          ... on WpPage_Acfpage_Page_NewsletterOptinBlock {
            buttonCopy
            fieldGroupName
            inputPlaceholder
            openingText
            successSmallMessage
            existingSubscriptionSmallMessage
            invalidEmailErrorMessage
            successBigMessage
            existingSubscriptionBigMessage
          }
          ... on WpPage_Acfpage_Page_ParagraphBlock {
            backgroundColor
            columns
            alignment
            textColour
            fieldGroupName
            copy
          }
          ... on WpPage_Acfpage_Page_SingleImageBlock {
            alignment
            blendMode
            file {
              altText
              mediaDetails {
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
            columns
            fieldGroupName
          }
          ... on WpPage_Acfpage_Page_StoriesIndexBlock {
            fieldGroupName
            title
            stories {
              ... on WpStory {
                id
                slug
                title
                ACFStoryGeneral {
                  storyPageBackgroundColor
                  imageBlendingMode
                }
                ACFStory {
                  storyCover {
                    copyColor
                    coverVideoLink {
                      vimeoLinkDesktop
                      vimeoLinkMobile
                    }
                    mediaType
                    coverImageFile {
                      altText
                      title
                      mediaDetails {
                        sizes {
                          height
                          name
                          sourceUrl
                          width
                        }
                      }
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Acfpage_Page_VideoBlock {
            alignment
            autoplay
            columns
            fieldGroupName
            vimeoLink {
              fieldGroupName
              portraitLink
              landscapeLink
            }
          }
          ... on WpPage_Acfpage_Page_ListsBlock {
            backgroundColor
            fieldGroupName
            textColour
            rightList
            openingText
            leftList
          }
          ... on WpPage_Acfpage_Page_VisualTextBlock {
            backgroundColor
            blockWidth
            copy
            fieldGroupName
            imageSize
            textColor
            withImage
            link {
              url
              title
              target
            }
            file {
              altText
              title
              mediaDetails {
                sizes {
                  height
                  name
                  sourceUrl
                  width
                }
              }
              mediaItemUrl
            }
          }
          ... on WpPage_Acfpage_Page_FeaturedProductBlock {
            backgroundColor
            blockWidth
            fieldGroupName
            productSku
            textColour
          }
          ... on WpPage_Acfpage_Page_ProductsGridBlock {
            productsGrid
            fieldGroupName
          }
        }
      }
      seo {
        ...seoFields
      }
    }
  }
`

export default Page
